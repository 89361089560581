export default{
    global:{
        tableLab1:'Tokens',
        tableLab2:'Algo',
        tableLab3:'Active miners',
        tableLab4:'Hashrate',
        tableLab5:'Network',

        termsTtitle1:"User Privacy Policy",
        termsTtitle2:"Reminder terms",
        termsLab1:"Release Date",
        termsLab2:"VERSION",

        detailsText:"Some users have reported that SMH computing power can be displayed on the X-proxy page but cannot be displayed on the website. After inspection, it was found that some users did not have their Apikey in X-proxy effective (or not filled in). You can try deleting the db file in the X-proxy folder, then resetting Apikey and restarting Miner and X-proxy. Attention: Due to the ineffective Apikey filled in X-proxy, the computing power cannot be attributed to the account. For example, if the file has completed PoET but is not displayed in the account, running the software after resetting can make the computing power belong to the correct account.",
        detailsText1:"Please carefully read and fully understand this Privacy Policy before using the products/services we provide (we have bolded the font for key content, please pay special attention to it) and make corresponding choices. Once you use or continue to use our services, it means that you agree to our collection, use, storage, sharing, and protection of your relevant information in accordance with this privacy policy. If you have any questions about this privacy policy, you can visit our official website https://www.h9.com Contact us through our customer service system.",
        detailsText2:"Please note that you should ensure that all data and information provided during the use of H9 services are true and obtained through legal means. If it contains third-party information, please confirm that you have obtained full and legal authorization from the owner of the information. Once you provide such information during the use of our services, we will consider that you have the right to handle such information, and there are no reasons/defects/scenarios that violate laws, regulations or infringe on the legitimate rights of any third party.",
        detailsText3:"Except for the information collection and use activities specified in this policy, this policy does not apply to other services provided to you by third parties. Other services are subject to the privacy policy and other personal information collection and use rules separately explained to you. For example, when you log in to the H9 website using the methods of 'WeChat', 'email', and 'phone number', the rules specifically explained to you by the WeChat operator, your telecommunications service operator, etc. apply to the collection and use of your personal information.",
        detailsText4:"This policy explains how H9 processes your personal information, but it does not cover all processing scenarios. The products/services discussed, mentioned, and introduced in this policy may vary depending on your geographical location. How specific products/services handle your personal information is explained by H9 in the specific privacy notice or supplementary policy published by the product/service. When using specific products/services, in addition to this policy, it is also recommended that you read relevant privacy notices or supplementary policies.",
        detailsText5:"This 「reminder clause」 is an integral part of the main body of this privacy policy.",

        toolLab1:"News",
        toolLab2:"Details",
        news1:"[Important] Ghduh-5 Software Upgrade Announcement",
        navLab1:'Log in',
        navLab2:'Register',
        navLab3:'Notice',
        navLab4:'Terms of use',
        navLab5:"Close",
        navLab6:"Home",
        navLab7:"Search",
        navLab8:"Develop",
        navLab9:"About Us",
        navLab10:"Please enter search content",

        footerLab1:"If you use this platform, it means that you have agreed to our",
        footerLab2:"Terms of use",
        footerLab3:"and",
        footerLab4:"Privacy Policy",
        footerBtn1:"Confirm",
        inpPlac:"Please select",
        tabLab1:"All",
        tabLab2:"Innovative products",
        tabLab3:"Testing item",
        tabCol1:"Pool name",
        tabCol2:"Platform Hashrate",
        tabCol3:"Network Difficulty",
        tabCol4:"new blocks in 24H",
        tabCol5:"Price",
        tabCol6:"Daily average revenue",
        tabCol7:"Consensus algorithm",
        tabBox1:"Daily average revenue",
        tabBox2:"platform mode",
        tabBox3:"Hangup service fee",
        tabBox4:"Time of Benefits Distribution",
        tabBox5:"Raise the amount",
        tabBox6:"Time of Withdrawal Review",
        infoTitle1:"WHY CHOOSE US?",
        infoLab1:"Secure and stable",
        infoLab2:"Open and transparent",
        infoLab3:"Secure and stable",
        infoText1:"Top tier technician team, distributed architecture supports millions of devices for concurrent hanging, and a 24 * 7 stable node network environment.",
        infoText2:"The revenue is automatically settled and paid on a daily basis, and the revenue data is open and transparent in real-time",
        infoText3:"The platform provides notification services such as in-site messaging, text messages, and emails, and promptly sends notifications when devices are disconnected.",
        footerLeba1:'Contact Us',
        noOpenLab:"Not open yet",
        bannerLab1:'Total Por',
        bannerLab2:'Provider Por',
        bannerLab3:'Number Blocks Prouced',
        bannerLab4:'Remain Reward',
        bannerLab5:'Reward',
        bannerLab6:'Por Mining',
    },
}