<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import {isMobile} from '@/common/utils/index'

export default {
  name: 'App',
  components: {
  },
  created(){
    if(isMobile()){
        this.$store.dispatch('toggleDevice', 'mobile')
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
  @import "./assets/icon/iconfont.css";
  :root {
    font-family: '微软雅黑';
    font-size: 16px;
  }


</style>

<style lang="scss">
.context{
  min-height:500px;
}
.el-pagination {
    &.is-background {
        .el-pager {
            li {
                &:not(.disabled) {
                    &.active {
                        background-color: #9E6AFF !important;
                    }

                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}
</style>
