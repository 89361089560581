import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'
import i18n from './common/lang/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import {getCurrentLang} from './common/lang/langHelp'

// 样式清除
import reset from './style/reset.css'

Vue.config.productionTip = false
if (getCurrentLang()=="en") {
  Vue.use(ElementUI, { locale })
}else{
  Vue.use(ElementUI)
}


new Vue({
  i18n,
  reset,
  router,
  store,
  render: function (h) { return h(App) },
}).$mount('#app')
