export default{
    global:{
        tableLab1:'币种',
        tableLab2:'矿工算法',
        tableLab3:'有效矿工',
        tableLab4:'矿池算力',
        tableLab5:'全网算力',

        termsTtitle1:"使用者隐私政策",
        termsTtitle2:"提示条款",
        termsLab1:"发布日期",
        termsLab2:"版本号",

        detailsText:"部分用户反映在X-proxy的页面可以显示SMH算力但是无法在网站上显示，后检查发现部分用户的X-proxy内Apikey未生效（或未填写）。可尝试删除X-proxy文件夹内的db文件，然后重新设置Apikey并重启Miner和X-proxy。注意：由于X-proxy内填写的Apikey未生效导致算力无法归属于账户下，例如文件已完成PoET但却不显示在账户内，重新设置完成后运行软件可以使算力归属到正确的账户下。",
        detailsText1:"请您在使用我们提供的各项产品/服务前，仔细阅读并充分理解本《隐私政策》（重点内容我们已将字体加粗请您特别关注）并作出相应选择。 一旦您使用或继续使用我们的服务时，即意味著您同意我们按照本隐私政策收集、使用、保存、共用和保护您的相关信息。  如对本隐私政策有任何疑问，您可以通过我们的官网https://www.h9.com的客服系统与我们联系。",
        detailsText2:"请您注意，您应保证在使用H9服务中所提供的一切数据资讯均为真实，且经过合法途径获得，如其中包含第三方资讯，请确认您已取得该资讯所有方的充分、合法的授权，一旦您在使用我们服务的过程中提供此类资讯，我们将认為该类资讯均為您有权处理，不存在任何违反法律 、法规或侵犯任何第三方合法权利的事由/瑕疵/场景。",
        detailsText3:"除本政策说明的相关信息收集使用活动外，本政策不适用於其他第三方向您提供的其他服务，其他服务适用其向您另行说明的隐私政策等个人资讯收集使用规则。 例如，当您使用「微信」「邮箱」「手机号」方式登录H9网站时，则微信运营方、 您的电信业务运营商等收集使用您的个人资讯适用其向您专门说明的规则。",
        detailsText4:"本政策阐述了H9如何处理您的个人资讯，但本政策不涵盖所有的处理场景，本政策讨论、提及、介绍的產品/服务视您的所在地理位置而有不同。 具体產品/服务如何处理您的个人资讯由H9在该產品/服务发佈的专门的隐私通知或补充政策中阐述。 在使用具体產品/服务时，除本政策外，还建议您阅读有关隐私通知或补充政策。",
        detailsText5:"本「提示条款」為本隐私政策正文的组成部分。",

        toolLab1:"最新公告",
        toolLab2:"详情",
        news1:"【重要】Ghduh-5软件升级公告",
        navLab1:'登录',
        navLab2:'注册',
        navLab3:'公告',
        navLab4:'使用条款',
        navLab5:"关闭",
        navLab6:"首页",
        navLab7:"搜索",
        navLab8:"开发",
        navLab9:"关于我们",
        navLab10:"请输入搜索内容",

        footerLab1:"如果您使用这个平台，表明您已经同意我们的",
        footerLab2:"使用条款",
        footerLab3:"以及",
        footerLab4:"隐私政策",
        footerBtn1:"确认",
        inpPlac:"请选择",
        tabLab1:"全部",
        tabLab2:"创新品",
        tabLab3:"测试品",
        tabCol1:"池名称",
        tabCol2:"平台算力",
        tabCol3:"全网难度",
        tabCol4:"24H总爆块",
        tabCol5:"价格",
        tabCol6:"日平均收益",
        tabCol7:"共识算法",
        tabBox1:"日平均收益",
        tabBox2:"平台模式",
        tabBox3:"挂机服务费",
        tabBox4:"发放收益时间",
        tabBox5:"提起金额",
        tabBox6:"提现审核时间",
        infoTitle1:"为什么选择我们?",
        infoLab1:"安全稳定",
        infoLab2:"公开透明",
        infoLab3:"安全稳定",
        infoText1:"顶级科技团队，分布式架构支持百万设备并发掛机，7*24小时稳定的节点网路环境. ",
        infoText2:"每天自动结算并支付收益，收益数据实时公开透明. ",
        infoText3:"平台提供站内信、简讯、邮件等通知服务，设备掉线及时发送通知. ",
        footerLeba1:'联系我们',
        noOpenLab:"暂未开放",
        bannerLab1:'全网算力',
        bannerLab2:'矿池算力',
        bannerLab3:'爆块数',
        bannerLab4:'未释放',
        bannerLab5:'已释放',
        bannerLab6:'产值',
    },
}