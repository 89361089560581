import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        name:'名字',
        globalTokenCheck:false,
        device: 'desktop',
        showFooter:true,
    },
    mutations: {
        TOGGLE_DEVICE: (state, device) => {
            state.device = device
        },
        /**
         * 设置名称
         * @param {*} state 
         * @param {*} value 
         */
        setName(state,value){
            state.name = value
        },
        setHideFooter(state){
            state.showFooter = false
        },
        // 全局登录token检测
        GLOBALTOKENCHECK (state) {
            state.globalTokenCheck = true
            sessionStorage.setItem('globalTokenCheck', state.globalTokenCheck)
        }
    },
    actions: {
        toggleDevice({ commit }, device) {
            commit('TOGGLE_DEVICE', device)
        },
    },
    modules: {}
})