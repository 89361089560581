
const storageKey = 'system_language';
let language = "zh";
/**
 * 改变语言
 */
const changeLanguages = function(_i18n,lang){
	lang = (lang.indexOf('zh') != -1 ? 'zh' : 'en');
	_i18n.locale = lang;
	language = lang;
  	window.sessionStorage.setItem("lang", lang);
	setDocumentLan(language)
	return lang;
}

const setDocumentLan = function(lan){
  	// 获取文档的根元素  
	var htmlElement = document.documentElement;  
	// 修改lang属性为"en"  
	htmlElement.setAttribute('lang', lan);
}

/**
 * 初始应用语言
 */
const initLanguage = function(){
  var _language = sessionStorage.getItem("lang");
  if(_language!=null){
    language = _language;
  }

  setDocumentLan(language)

	return language;
}

/**
 * 获得当前设置的语言
 */
const getCurrentLang= function(){
	return (language.indexOf('zh') != -1 ? 'zh' : 'en');
}

/**
 * 获得当前设置的语言
 */
const getCurrentLanguage = function(){
	return language;
}


export {
	initLanguage,
	changeLanguages,
	getCurrentLang,
	getCurrentLanguage
}