import LangEn from './en.js'
import LangChs from './zh.js'
import Vue from 'vue'
import VueI18n from './vue-i18n'
import {initLanguage} from './langHelp.js'

Vue.use(VueI18n)

const cur_lang = initLanguage();

const i18n = new VueI18n({
	locale: cur_lang , // 默认选择的语言
	messages: {
		'en': LangEn,
		'zh': LangChs
	}
})
export default i18n