import Vue from 'vue'
import VueRouter from 'vue-router'
import { initLanguage } from '@/common/lang/langHelp.js'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: "/",
        component: () => import('@/views/layout/Default'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('@/views/Home'),
                meta: {
                    title: {
                        'zh': '首页',
                        'en': 'Home'
                    }
                }
            },
            {
                path: 'noticeList',
                name: 'noticeList',
                component: () => import('@/views/NoticeList'),
                meta: {
                    title: {
                        'zh': '公告列表',
                        'en': 'NoticeList'
                    }
                }
            },
            {
                path: 'details',
                name: 'details',
                component: () => import('@/views/Details'),
                meta: {
                    title: {
                        'zh': '公告详情',
                        'en': 'Details'
                    }
                }
            },
            {
                path: 'termsUse',
                name: 'termsUse',
                component: () => import('@/views/TermsUse'),
                meta: {
                    title: {
                        'zh': '使用条款',
                        'en': 'terms of use'
                    }
                }
            },
            {
                path: 'privacy',
                name: 'privacy',
                component: () => import('@/views/Privacy'),
                meta: {
                    title: {
                        'zh': '使用者隐私政策',
                        'en': 'User Privacy Policy'
                    }
                }
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('@/views/About'),
                meta: {
                    title: {
                        'zh': '关于我们',
                        'en': 'About Us'
                    }
                }
            },
            
            
        ]
    },

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const cur_lang = initLanguage();
    if (to.meta && to.meta.title) {
      if (cur_lang == "zh") {
        var title = to.meta.title.zh
        document.title ="Flypool | "+title || 'Flypool|默认标题';
      } else {
        var title = to.meta.title.en
        document.title = "Flypool | "+title || 'Flypool|default title';
      }
    }
    next();
  });

export default router